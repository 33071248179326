@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.chatHeader {
  box-shadow: 0 1px 3px #0000001C;
  @include bradius(8px, 8px, 8px, 8px);
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-size: 19px;
    font-weight: 600;
    color: $secondary-text-color;
    line-height: 20px;
  }
  .chatInfoImage {
    width: 40px;
    margin-left: 25px;
  }
  .chatHeaderImg {
    width: 40px;
    margin-left: 15px;
    width: 25px;
    height: 25px;
  }
  .deleteImage {
    width: 20px;
    margin-left: 20px;
  }
  .requestImage {
    width: 24px;
    border-radius: 5px;
    margin-left: 25px;
  }
  .removeRoom {
    background-color: $secondary-text-color;
    color: $secondary-bg-color;
    padding: 3px 10px;
    margin-left: 10px;
    border-radius: 25px;
  }
  .userRequestCounter {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: #FFFFFF;
    font-size: 12px;
    position: absolute;
    top: -15px;
    right: -15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
.publicProfileWrapper {
  max-width: 100%;
  padding-left:2rem;
  padding-right:2rem;
  padding-top:1rem;
  padding-bottom:3rem;
  border-radius: 5px;
}

.userDetails {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.userDetails > img {
  width: 100%;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  min-width: 70px;
  max-width: 70px;
}
.detailsInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.closeButton {
  max-width: 7.125rem;
  height: 30px;
  margin-bottom: 10px;
  width: 100%;
}

.modalTitleClass {
  font-size: 5px;
  color: #FFFFFF;
}

.modalTopConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  .joiImage {
    width: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .createRoomWidth {
    max-width: 230px;
    margin-top:1rem;
  }
  .modalBottomContent {
    max-width: 280px;
    font-size: 13px;
    align-items: center;
    padding-top:1rem;
  }
  .superHostImg {
      width: 20px;
      height: 20px;
      position: absolute;
  }
}
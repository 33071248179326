@import "src/assets/css/mixins";
@import "src/assets/css/variables";
.chatContentWrapper {
  height: calc(100% - 190px);
  padding-inline: 5px;
  overflow-y: auto;
  margin-block: 15px;
  position: relative;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}
.typingUser {
  display: inline-block;
  padding-bottom: 8px;
}
.attachedMedia {
  background-color: $bg-grey;
  padding: 10px;
  position: absolute;
  bottom: 80px;
  width: calc(100% - 10px);
  margin-inline: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 2px dotted rgba(0,0,0,0.6);
  > div {
    position: relative;
    width: 40px;
    .attachedFile {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      display: inline-block;
    }
    .closeIconWrapper {
      display: none;
      position: absolute;
      top: -7px;
      right: -5px;
      cursor: pointer;
      background: black;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      img {
        width: 8px;
        position: absolute;
        top: 3px;
        left: 3px;
      }
    }
  }
  .attachedInnerMedia {
    &:hover .closeIconWrapper {
      display: block;
    }
  }
}

.joinRoom {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 30px;
  p {
    color: $text-field-light-color;
    font-size: 25px;
    font-weight: 600;
    line-height: 20px;
  }
}

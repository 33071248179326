@import 'src/assets/css/variables';

.wrapper {
  .heading {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0;
    color: #484848;
  }
  .detail {
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0;
    color: #484848;
  }
  .formWrapper {
    margin-top: 20px;
    .sendButton {
      padding: 13px 77px;
      max-width: 191px;
    }
  }
}
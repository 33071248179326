@import 'src/assets/css/variables';
.customContainer {
  width: 100%;
  display: flex;
  .leftSectionWrapper {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00aeef;
    opacity: 0.8;
    position: relative;
    &:after {
      content: '';
      background-image: url('../../../assets/images/triangle-background.png');
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      height: 400px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .rightSectionWrapper {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .loginArea {
      padding-inline: 110px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 100vh;
      text-align: center;
      .tagLine {
        font-size: 23px;
        font-weight: 500;
        color: $primary-text-color;
        margin: 40px 0;
      }
      .profileTitle {
        font-size: $font-fifteen;
        color: $primary-text-color;
        font-weight: 500;
      }
      .confirmEmailBtn {
        max-width: 270px;
        margin: 30px auto 0;
      }
      .otpContainer {
        margin-top: 20px;
      }
      .otpInput {
        width: 50px !important;
        height: 50px !important;
      }
      .error {
        color: red;
      }
    }
  }
}

@import "src/assets/css/mixins";
@import "src/assets/css/variables";
aside {
  flex: 0 0 310px;
  max-width: 310px;
  width: 100%;
  padding-block: 10px;
  .nearbyRooms {
    background-color: $light-blue;
    padding: 20px;
    @include bradius(5px, 5px, 5px, 5px);
    margin-bottom: 15px;
    h6 {
      font-size: 18px;
    }
  }
  .nearbyRoomsListing {
    background-color: $light-blue;
    @include bradius(5px, 5px, 5px, 5px);
    height: calc(100% - 74px);
    padding: 20px 10px 10px;
    overflow: hidden;
    .status {
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      padding-inline: 20px;
    }
    .success {
      position: relative;
      color: $active-success;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
        width: 8px;
        height: 8px;
        @include bradius(50%, 50%, 50%, 50%);
        background-color: $active-success;
      }
    }
    .notActive {
      color: $text-field-light-color;
    }
    .activeListing {
      max-height: 60%;
      overflow-y: auto;
    }
    .offlineListing {
      max-height: 30%;
      overflow-y: auto;
    }
    .offlineListingFull {
      max-height: 90%;
      overflow-y: auto;
    }
    .offlineListingOne {
      max-height: 70%;
      overflow-y: auto;
    }
    .offlineListingTwo {
      max-height: 50%;
      overflow-y: auto;
    }
    .offlineListingThree {
      max-height: 30%;
      overflow-y: auto;
    }
  }
}

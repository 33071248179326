@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.explainerVideo {
  text-align: center;
  .heading {
    margin-bottom: 40px;
    text-align: center;
    font: normal normal 600 50px/57px Poppins;
    letter-spacing: 0;
    color: #484848;
    opacity: 1;
    .headingSpan {
      color: $secondary-text-color;
    }
  }
}
.videoSection {
    padding: 30px 0 0 0;
  }
@import "src/assets/css/variables";
@import "src/assets/css/mixins";

.modalWrapper {
  padding: 5px 70px 20px;
  text-align: center;
  img {
    width: 40px;
  }
  .description {
    font-size: 23px;
    color: $primary-text-color;
    font-weight: 600;
    line-height: 33px;
    margin: 15px auto 25px;
  }
}

@import 'src/assets/css/variables';
.superHostImage {
  max-width: 110px;
}
.listingWrapper {
  text-align: left;
  position: relative;
  margin-top: 50px;
  margin-inline: auto;
  max-width: 26rem;
  li:before {
    content: '';
    background-image: url("../../assets/svg/checkmark_orange.svg");
    width: 20px;
    height: 20px;
    background-size: contain;
    position: absolute;
    left: -30px;
    margin-top: 2px;
  }
  li {
    padding-bottom: 25px;
    font-size: 18px;
    color: $primary-text-color;
    font-weight: normal;
  }
}
.subscriptionWrapper {
  width: 35%;
  padding: 25px 50px 50px;
}
.rechargeAccountWrapper {
  max-width: 755px;
  width: 100%;
  padding: 25px 50px;
  .rechargeCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
  }
}

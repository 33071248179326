@import 'src/assets/css/mixins';
@import 'src/assets/css/variables';

.customContainer {
  width: 100%;
  display: flex;
  .leftSectionWrapper {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #00aeef;
    opacity: 0.8;
    position: relative;
    &:after {
      content: '';
      background-image: url('../../../assets/images/triangle-background.png');
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 400px;
      height: 400px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .rightSectionWrapper {
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .loginArea {
      padding-inline: 110px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: 100vh;
      text-align: center;
      .tagLine {
        font-size: 16px;
        font-weight: 400;
        color: $primary-text-color;
        margin: 40px 0;
      }
      .profileTitle {
        font-size: $font-fifteen;
        color: $primary-text-color;
        font-weight: 500;
      }
      .nextButton {
        width: 245px;
        margin: 30px auto 0;
        background-color: $secondary-text-color;
        padding: 12px 15px;
        @include bradius(30px, 30px, 30px, 30px);
        color: $text-white;
        font-size: $font-fourteen;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
      }
      .disabled {
        cursor: not-allowed;
      }
      .uploadFileWrapper {
        max-width: 400px;
        width: 100%;
        margin: 35px auto;
        height: 260px;
        @include bradius(5px, 5px, 5px, 5px);
        background-color: $bg-grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 28px;
        .uploadFileInner {
          width: 135px;
          height: 135px;
          @include bradius(50%, 50%, 50%, 50%);
          background-color: $text-white;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .cameraImage {
            width: 60px;
            height: 60px;
            object-fit: contain;
          }
          .uploadImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include bradius(50%, 50%, 50%, 50%);
          }
          input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
          }
        }
      }
    }
  }
}

.modalWrapperClass {
  max-width: 850px;
  width: 100%;
  padding: 40px 125px;
}
.userImagesWrapper {
  row-gap: 20px;
  width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  column-gap: 20px;
  img {
    @include bradius(50%, 50%, 50%, 50%);
    width: 130px;
    height: 130px;
  }
}
.gotButtonWidth {
  width: 270px;
  margin: 65px auto 0;
}

@media only screen and (min-width: 992px) {
  .modalWrapperClass {
    width: 850px;
  }
}
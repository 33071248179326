@import "src/assets/css/mixins";
@import "src/assets/css/variables";
header {
  background-color: #ffffff;
  height: 85px;
  box-shadow: 7px 0 10px #00000029;
  .headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .logoWrapper {
      flex: 0 0 220px;
      max-width: 220px;
      width: 100%;
      .logo {
        max-width: 220px;
        object-fit: contain;
      }
    }
  }
  .profileName {
    display: flex;
    align-items: center;
    column-gap: 20px;
    flex: 0 0 calc(100% - 220px);
    width: 100%;
    justify-content: flex-end;
    .createRoomWidth {
        max-width: 180px;
    }
    .divider {
      width: 1px;
      height: 28px;
      background-color: $primary-text-color;
    }
    .profileLogoWrapper {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      .profileIcon {
        width: 100%;
        min-height: 55px;
        height: 55px;
        max-height: 55px;
        border-radius: 50%;
        border: 4px solid $secondary-text-color;
        object-fit: cover;
      }
    }
    .dropDownWrapper {
      position: absolute;
      top: 90px;
      width: 275px;
      box-shadow: 0 3px 6px #0000001C;
      @include bradius(8px, 8px, 8px, 8px);
      padding: 30px 25px;
      right: 30px;
      z-index: 1;
      background-color: $text-white;
    }
    .bgDropDown {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }
    .dropDownInner {
      margin-bottom: 0;
      li {
        padding-bottom: 15px;
        &:last-of-type {
          padding-bottom: 0;
        }
        &:hover > a {
          color: $primary-text-color;
        }
        &:active > a {
          color: $primary-text-color;
        }
        &:focus > a {
          color: $primary-text-color;
        }
      }
      a {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
    .userNameWrapper {
      display: flex;
      align-items: center;
    }
    .userNameWrapper span{
      max-width: 100px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .createRoomWrapper {
    width: 40%;
    padding-inline: 50px;
    padding-block: 20px 50px;
    text-align: center;
    .nextBtn {
      max-width: 220px;
      margin-top: 50px;
    }
    .superHostImage {
      max-width: 115px;
    }
  .subscribeBtn {
    max-width: 270px;
    margin-top: 60px;
  }
  }
}
.rechargeAccountWrapper {
  max-width: 755px;
  width: 100%;
  padding: 25px 50px;
  .rechargeCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
  }
}

@import 'src/assets/css/variables';

.wrapper {
  position: relative;
  min-height: 100vh;
  //border-top: 13px solid $secondary-text-color;
  padding-bottom: 120px;
  .topHeader {
    width: 100%;
    background-color:$secondary-text-color;
    padding: 10px 15px;
    text-align: right;
    > a {
      border: 1px solid $text-white;
      color: $text-white;
      padding: 5px 10px;
      text-decoration: none;
      border-radius: 3px;
    }
  }
  .feature {
    margin-top: 76px;
    height: 24px;
    text-align: center;
    font: normal normal 600 17px/55px Poppins;
    letter-spacing: 0px;
    color: #AAAAAA;
    opacity: 1;
  }
  .heading {
    margin-bottom: 40px;
    text-align: center;
    font: normal normal 600 50px/57px Poppins;
    letter-spacing: 0;
    color: #484848;
    opacity: 1;
    .headingSpan {
      color: $secondary-text-color;
    }
  }
  .videoSection {
    max-width: 955px;
    width: 100%;
    margin-inline: auto;
    height: 553px;
    background: $text-white 0% 0% no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    padding: 22px 24px 21px 23px;
  }
  .learnMoreWrapper {
    .videoImage {
      margin-top: 76px;
      // display: none;
    }
    .learnMore {
      // margin-top: 76px;
      height: 24px;
      text-align: center;
      font: normal normal 600 17px/55px Poppins;
      letter-spacing: 0;
      color: $text-field-light-color;
    }
    .heading {
      margin-bottom: 40px;
      text-align: center;
      font: normal normal 600 50px/57px Poppins;
      letter-spacing: 0;
      color: $primary-text-color;
      .headingSpan {
        color: $secondary-text-color;
      }
    }
    .paragraph {
        text-align: center;
        font: normal normal normal 20px/30px Poppins;
        letter-spacing: 0;
        color: $primary-text-color;
        .link {
          color: $secondary-text-color;
          font-weight: 600;
          text-decoration: none;
        }
      }
  }

.backToTopIcon {
  position: fixed;
  bottom: 85px;
  right: 50px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $primary-btn-bg-color;
  cursor: pointer;

  img {
    display: block;
    width: 30px;
    margin: 0 auto 10px;
  }
}

  .footerSection {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $secondary-text-color;
    padding: 15px;
    display: flex;
    justify-content: center;
    img {
      width: 30px;
    }
    .copyRightText {
      width: 100%;
      height: 28px;
      text-align: center;
      font: normal normal 600 20px/24px Poppins;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    }
  }
}
.carouselWrapper {
  background: url('../../assets/images/about-video-bg.png') no-repeat center;
  background-size: contain;
  // height: 79vw;
  .sliderItem {
    margin-top: 170px;
  }
  .sliderItemCaption {
    font-size: 32px;
    line-height: 45px;
  }
}

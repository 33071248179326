@import "src/assets/css/mixins";
@import "src/assets/css/variables";

.bodyContent {
  height: calc(100vh - 224px);
  width: 100%;
  margin: 30px auto;
  @include bradius(8px, 8px, 8px, 8px);
  border: 1px solid $off-white;
  padding-inline: 10px;
  box-shadow: 0 2px 4px #0000001C;
  .startScreenWrapper {
    display: flex;
    justify-content: flex-start;
    column-gap: 16px;
    width: 100%;
    height: 100%;
    .joinRoom {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 30px;
      p {
        color: $text-field-light-color;
        font-size: 25px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
  .chatSection {
    flex: 0 0 calc(100% - 325px);
    max-width: calc(100% - 325px);
    width: 100%;
    height: 100%;
    background-color: $text-white;
    @include bradius(0px, 5px, 5px, 0px);
    padding-block: 10px;
    position: relative;
  }
  .chatSection.threadSection {
    flex: 0 0 calc(100% - 325px - 300px);
    max-width: calc(100% - 325px - 300px);
  }
  .notJoinedWrapper {
    background-color: #fafafa;
  }
}

.backToDashboard {
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 1;
  a {
    display: inline-block;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
}

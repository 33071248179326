$primary-btn-bg-color: #00aeef;
$font-eleven: 0.688rem; //front-size: 11px;
$font-thirteen: 0.813rem; //front-size: 13px;
$font-fourteen: 0.875rem; //front-size: 14px;
$font-fifteen: 0.938rem; //front-size: 15px;
$primary-font-size: 1rem; //front-size: 16px;
$section-title: 1.063rem; //front-size: 17px;
$secondary-heading-size: 1.25rem; //front-size: 20px;
$card-heading-size: 1.563rem; //front-size: 25px;
$font-thirty: 1.875rem; //front-size: 30px;
$main-heading-size: 2.188rem; //front-size: 35px;
$primary-text-color: #484848;
$secondary-text-color: #00aeef;
$secondary-bg-color: #f2fbff;
$warning-text-color: #fbb040;
$bg-yellow: #fbb040;
$text-field-light-color: #aaaaaa;
$text-white: #ffffff;
$text-danger: #de1414;
$border-radius: 23px;
$btn-height: 45px;
$input-height: 60px;
$bg-grey: #f7f7f7;
$off-white: #f7f7f7;
$column-gap-5: 5px;
$column-gap-10: 10px;
$column-gap-15: 15px;
$bg-success: #01c94a;
$light-blue: #f2fbff;
$active-success: #019818;
$offline-image-border: #FFA500
